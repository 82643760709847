import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Row, Col } from "react-bootstrap";

export const Contact = () => {
    const [buttonText, setButtonText] = useState('Send');
    const [status, setStatus] = useState({});

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setButtonText("Sending...")
        emailjs
        .sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID, 
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
            form.current, {
            publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        })
        .then(
            () => {
                setStatus({ success: true, message: "Message sent successfully!"});
                form.current.reset();
                setButtonText("Send");
                setTimeout(() => {
                    setStatus({});
                }, 5000);
            },
            (error) => {
                setStatus({ success: false, message: "Something went wrong, please try again later"});
                setTimeout(() => {
                    setStatus({});
                }, 5000);
                console.log(error);
            },
        );
    };

    return (
        <section className="contact" id="contact">
            <Container>
                <Row className="align-item-center">
                    <Col className="contact-left-col" md={6}>
                        <h2>Contact</h2>
                        <p>Reach out to me if you'd like to find out more about me.</p>
                        <h3>Discord: <br/>kaikailimmm</h3>
                        <br/>
                        <h3>hireyklim@gmail.com</h3>
                    </Col>
                    <Col md={6}>
                        <h2>Let's grab a coffee!</h2>
                        <form ref={form} onSubmit={sendEmail}>
                        <Row>
                            <input type="text" name="user_name" placeholder="Name" required="required"/>
                            <input type="email" name="user_email" placeholder="Email Address" required="required"/>
                            <textarea name="message" placeholder='Message'/>
                            <button type="submit" value="Send"><span>{buttonText}</span></button>
                        </Row>
                        {
                            status.message && 
                            <Row>
                                <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                            </Row>
                        }
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}