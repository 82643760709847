import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import projectEntry from "../components/projectEntry"
import Card from 'react-bootstrap/Card';

export const Projects = () => {
    return (
        <section className="projects" id="projects">
            <Container>
                <Row>
                    <Col>
                    <div className="projects-box">
                        <Row>
                            <h2>Projects</h2>
                            <p>Here are some of the personal projects that I have worked on.</p>
                        </Row>
                        <div className="scrollable-container">
                        <Row className="flex-nowrap">
                            {projectEntry.map((item) => (
                                <Card>
                                    <div className="card-img" >
                                        <Card.Img variant="top" src={item.img} />
                                    </div>
                                    <Card.Body className="d-flex flex-column justify-content-center card-body">
                                        <Card.Title>{item.title}</Card.Title>
                                        <Card.Subtitle>{item.longDesc}</Card.Subtitle>
                                        <br/>
                                        <Container className="description-sec">
                                            <Container className="project-desc-title">
                                                <Card.Text>Client: </Card.Text>
                                                <Card.Text>Technology: </Card.Text>
                                                <Card.Text>URL: </Card.Text>
                                            </Container>
                                            <Container className="project-desc">
                                                <Card.Text>{item.client}</Card.Text>
                                                <Card.Text>{item.technology}</Card.Text>
                                                {item.url ? (
                                                <Card.Link href={item.url}>{item.url}</Card.Link>
                                                ) : (
                                                <Card.Text style={{ color: 'red', fontWeight: 'bold' }}>Offline</Card.Text>
                                                )}
                                            </Container>
                                        </Container>
                                    </Card.Body>
                                </Card>
                            ))}
                        </Row>
                        </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}