import 'react-multi-carousel/lib/styles.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import rhceImage from "../assets/img/rhce.png";
import rhcoaImage from "../assets/img/rhcoa.png"
import awsmlImage from "../assets/img/aws-ml.png"
import rhportfolioImage from "../assets/img/redhat-portfolio-foundational.png"
import rhostechicalsellerImage from "../assets/img/redhat-openshift-technical-seller.png"
import rhossellerImage from "../assets/img/redhat-openshift-seller.png"

export const Certificate = () => {
    const [index, setIndex] = useState(0);
    const [interval, setInterval] = useState(3000); // default autoscroll time is 3 secs

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
        setInterval("0"); //set interval to 0 when arrows are clicked
    };

    return (
        <section className='certificate' id='certificate'>
            <Container interval={interval} activeIndex={index} onSelect={handleSelect}>
                <Row>
                    <Col>
                        <div className='certificate-box'>
                        <h2>I'm certified in...</h2>
                            <Carousel>
                                <Carousel.Item>
                                    <img src={awsmlImage} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={rhceImage} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={rhcoaImage} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={rhportfolioImage} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={rhossellerImage} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={rhostechicalsellerImage} />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}