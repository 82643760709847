import nottinghamLogo from "../assets/img/nottingham-logo.jpg"

let educationTimeline = [
{
    id: 1,
    title: "University of Nottingham",
    location: "Semenyih, Malaysia",
    description:
      "After graduating high school, I took a pre-university diploma to prepare myself for Bachelor's Degree. I started off with C and gained a good understanding of algorithms. I also completed a web app project utilising HTML, CSS and JavaScript.",
    date: "Sep 2018 - Jun 2019",
    icon: nottinghamLogo
  },
  {
    id: 2,
    title: "University of Nottingham",
    location: "Nottingham, United Kingdom",
    description:
      "I graduated from my Bachelor's Degree with First Class Honours. I have completed full stack software engineering projects, creating a mobile healthcare app using Dart and Flutter. I also completed a individual dissertation which is a system utilising deep learning to predict the occupancy rate of a carpark at a given time.",
    date: "Sep 2019 - Jun 2022",
    icon: nottinghamLogo
  },
]

export default educationTimeline;