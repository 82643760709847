import { Container, Row, Col } from "react-bootstrap";

export const Skills = () => {
    return (
        <section className="skills" id="skills">
            <Container>
                <Row>
                    <Col>
                        <div className="skills-box">
                            <h2>Skills</h2>
                            <p>Here is a summary of my most important skills and abilities as a Software Engineer</p>
                            <div className="skills-sec">
                                <Row>
                                    <Col md={6} xs={12} className="skills-cols">
                                        <h3>Coding</h3>
                                        <dl className="coding">
                                            <dt>HTML/CSS</dt>
                                            <dd>* * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>JavaScript</dt>
                                            <dd>* * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>Python</dt>
                                            <dd>* * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>MySQL</dt>
                                            <dd>* * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>React/React Native</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>Tensorflow/Keras</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>PyTorch</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>Scikit-Learn</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>Apache Spark</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>LLM</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>Hugging Face</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>Django/Flask</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>Ansible</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>OpenShift</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>Docker</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>Kubernetes</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>Git</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="coding">
                                            <dt>ElasticSearch</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                    </Col>
                                    <Col md={6} xs={12} className="skills-cols">
                                        <h3>Language</h3>
                                        <dl className="language">
                                            <dt>Mandarin (Mother tongue)</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="language">
                                            <dt>English (Native)</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="language">
                                            <dt>Cantonese (Fluent)</dt>
                                            <dd>* * * *</dd>
                                        </dl>
                                        <dl className="language">
                                            <dt>Malay (Fluent)</dt>
                                            <dd>* * * *</dd>
                                        </dl>
                                        <dl className="language">
                                            <dt>Japanese (Beginner)</dt>
                                            <dd>* *</dd>
                                        </dl>
                                        <br/>
                                        <h3>Tools / Others</h3>
                                        <dl className="language">
                                            <dt>Windows</dt>
                                            <dd>* * * * *</dd>
                                        </dl>
                                        <dl className="language">
                                            <dt>Linux (RHEL, Ubuntu, Debian)</dt>
                                            <dd>* * * *</dd>
                                        </dl>
                                        <dl className="language">
                                            <dt>MacOS</dt>
                                            <dd>* * * *</dd>
                                        </dl>
                                        <dl className="language">
                                            <dt>AWS</dt>
                                            <dd>* * * *</dd>
                                        </dl>
                                        <dl className="language">
                                            <dt>Azure</dt>
                                            <dd>* * * *</dd>
                                        </dl>
                                        <dl className="language">
                                            <dt>GCP</dt>
                                            <dd>* *</dd>
                                        </dl>
                                        <dl className="language">
                                            <dt>GitHub / Gitlab</dt>
                                            <dd>* *</dd>
                                        </dl>
                                        <dl className="language">
                                            <dt>Elastic</dt>
                                            <dd>* *</dd>
                                        </dl>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
