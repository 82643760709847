import { Container, Row, Col } from "react-bootstrap";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import educationTimeline from "./educationTimeline"


export const Education = () => {
    return (
        <section className="education" id="education">
        <Container>
            <Row>
                <Col>
                    <div className="education-box">
                        <h2>Education</h2>
                        <VerticalTimeline className="education-vertical-timeline">
                            {educationTimeline.map((item) => (
                                <VerticalTimelineElement
                                key={item.id}
                                date={item.date}
                                dateClassName="date"
                                icon={<img src={item.icon} alt="uni logo" style={{ borderRadius: '50%' }} />}
                                >
                                <h2>{item.title}</h2>
                                <p className="location">{item.location}</p>
                                <p>{item.description}</p>
                                </VerticalTimelineElement>
                            ))}
                        </VerticalTimeline>
                    </div>
                </Col>
            </Row>
        </Container>
        </section>
    )
}