import { useState, useEffect } from "react";
import { Navbar, Container, Nav } from 'react-bootstrap';
import linkedinIcon from '../assets/img/linkedin-icon.svg';
import githubIcon from "../assets/img/github-icon.svg"
import whatsappIcon from "../assets/img/whatsapp-icon.svg"
import gmailIcon from "../assets/img/gmail-icon.svg"
// import navIcon2 from '../assets/img/nav-icon2.svg';
// import navIcon3 from '../assets/img/nav-icon3.svg';


export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    const onUpdateActiveLink = (value) =>{
        setActiveLink(value);
    }

    return (
        <Navbar expand="md" className={scrolled ? "scrolled": ""}>
        <Container>
            {/* <Navbar.Brand href="#home">
                <img src={logo} alt="Logo" />
            </Navbar.Brand> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav">
                <span className='navbar-toggler-icon'></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                <Nav.Link href="#education" className={activeLink === 'education' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('education')}>Education</Nav.Link>
                <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
                <Nav.Link href="#experience" className={activeLink === 'experience' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('experience')}>Experience</Nav.Link>
                <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
                <Nav.Link href="#contact" className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contact')}>Contact</Nav.Link>
            </Nav>
            <span className='navbar-text'>
                <div className='social-icon'>
                    <a href="https://www.linkedin.com/in/yen-kai-lim/" target="_blank" rel="noreferrer"><img src={linkedinIcon} alt="LinkedIn Profile" /></a>
                    <a href="https://github.com/limyenkai" target="_blank" rel="noreferrer"><img src={githubIcon} alt="Github Profile" /></a>
                    <a href="https://wa.me/6588834821" target="_blank" rel="noreferrer"><img src={whatsappIcon} alt="Whatsapp Link" /></a>
                    <a href="mailto:hireyklim@gmail.com" target="_blank" rel="noreferrer"><img src={gmailIcon} alt="Email Link" /></a>
                </div>
                {/* <button className="connect-btn" onClick={() => window.open("https://www.linkedin.com/in/yen-kai-lim/", "_blank")}><span>Let's Connect</span></button> */}
            </span>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    );
}
