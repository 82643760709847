import healthguardLogo from "../assets/img/healthguard-logo.jpeg"
import electionImg from "../assets/img/election-img.jpg"
import nccLogo from "../assets/img/ncc-logo.jpg"
import drowsyDriverImg from "../assets/img/drowsy-driver.jpg"
import digitRecognitionImg from "../assets/img/digit-recognition.jpeg"
import onlineShoppingImg from "../assets/img/online-shopping.jpeg"
import llmImg from "../assets/img/llm-applications-main.jpg"
let projectEntry = [
    {
        id: 1,
        img: nccLogo,
        title: "Parking Recommendation System",
        client: "University Project (Individual Dissertation)",
        longDesc: "A software that helps predict the occupancy rate of parking lots in Nottingham at a given time. \
                    Utilising ANN a model was trained based on the historical parking data which was retrieved \
                    through the Nottingham City Council (NCT) API. An in house recommender system is also built to help recommend users \
                    on where to park based on the predicted occupancy.",
        technology: "Python, TensorFlow, Keras",
        url: "https://github.com/limyenkai/NCTParkingPrediction",
    },
    {
        id: 2, 
        img: llmImg,
        title: "Local LLM Document Querying",
        client: "Personal Project",
        longDesc: "In an effort to do question and answering of our own private data, LLM model run locally was built. \
                    Utilising 4 bit quantised LLM model (TheBloke/wizardLM-7B-HF) from Hugging Face, \
                    personal document was loaded and embedded into Chroma DB. \
                    With the embedded data, similarity search retriever and the LLM model chained together, \
                    we are able to make queries on the document loaded. ",
        technology: "Hugging Face, Chroma DB, Transformers, Quantization, OpenAI",
        url: "https://github.com/limyenkai/llm-projects",
    },
    {
        id: 3, 
        img: electionImg,
        title: "Analysing Correlation Between Tweets and Actual Election Result",
        client: "University Project (Big Data)",
        longDesc: "This project involves carrying out sentiment analysis using Naive Bayes on a million tweets related to the 2020 Trump vs Biden election. \
                    Parallel computing using PySpark was employed to speed up the correlation analysis process.",
        technology: "Python, DataBricks, Spark",
        url: "",
    },
    {
        id: 3,
        img: healthguardLogo,
        title: "HealthGuard",
        client: "University Project (Software Engineering Group Project)",
        longDesc: "An application that is built to centralise all users' health related needs. \
                    This app which works on both iOS and Android includes features such as user medical health report, Pedometer, \
                    hospital suggestions and also a live consultation with a doctor.",
        technology: "Dart, Flutter, Git, Firebase",
        url: "https://github.com/liewyihseng/HealthGuard",
    },
    {
        id: 4,
        img: drowsyDriverImg,
        title: "Drowsy Driver Detection",
        client: "Personal Project",
        longDesc: "A model trained on large amount of pictures with opened and closed eyes examples. \
                    Transfer learning was done on a MobileNet computer vision model. \
                    A fine-tuned model was created to identify if a person's eye is opened or closed. \
                    If it is closed for an extended duration of time, an alert will be sound. This system is designed to detect and prevent \
                    driver drowsiness",
        technology: "Python, TensorFlow, OpenCV, Numpy",
        url: "https://github.com/limyenkai/Driver-Drowsiness-Detection",
    },
    {
        id: 5, 
        img: digitRecognitionImg,
        title: "Handwritten Digit Classifier",
        client: "Personal Project",
        longDesc: "A CNN model capable of identifying handwritten digits. Using PyTorch a model was successfully trained on MNIST Dataset \
                    to identify handwritten digits.",
        technology: "Python, PyTorch",
        url: "https://github.com/limyenkai/digit-classifier",
    },
    {
        id: 6, 
        img: onlineShoppingImg,
        title: "Sales Prediction of Amazon Products",
        client: "Personal Project",
        longDesc: "Amazon past sales data was obtained from Kaggle. Then multiple data cleaning and analysis techniques were applied \
                    to ensure that any outliers and inaccurate data points are handled accordingly. After that, a machine learning model \
                    was trained using the cleaned data using XGBoost. The trained model proved to make accurate predictions on unseen data.",
        technology: "Pandas, Numpy, Seaborn, Matplotlib, Scikit-learn",
        url: "https://github.com/limyenkai/sales_prediction",
    },
]

export default projectEntry;